import React, {Component} from 'react';

class LandingPage extends Component {
    render() {
        return (
            <div>
                <div className="col-md-12 text-center landing-box">
                    <div className="main-name">
                        Andy Baxter
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 h3'>
                            R Training
                        </div>
                        <div className='col-sm-6 h3'>
                            R Consultancy
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LandingPage;