import ContactForm from './ContactForm';
import './App.css';
import './App.scss';
import LandingPage from './LandingPage';

function App() {
  return (
    <div className="App container col-sm-8 justify-content-center">
        <div className='row justify-content-center'>
          <LandingPage />
        </div>
        <div className='row justify-content-center'>
          <ContactForm />
        </div>
    </div>
  );
}

export default App;
