import React, { Component } from "react";

// const FORM_ENDPOINT = "http://localhost:8080"
const FORM_ENDPOINT = "https://public.herotofu.com/v1/81e178e0-fb22-11ec-bc36-e1ea9ccadd33"

class ContactForm extends Component {

    constructor () {
        super()
        this.status = "not submitted";
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const inputs = e.target.elements;
        const data = {};

        for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].name) {
            data[inputs[i].name] = inputs[i].value;
        }
        }

        const injectedData = {
            FROM: "main page",
          };

    Object.assign(data, injectedData);

    fetch(FORM_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        // It's likely a spam/bot request, so bypass it to validate via captcha
        if (response.status === 422) {
          Object.keys(injectedData).forEach((key) => {
            const el = document.createElement("input");
            el.type = "hidden";
            el.name = key;
            el.value = injectedData[key];

            e.target.appendChild(el);
          });

          e.target.submit();
          throw new Error("Please finish the captcha challenge");
        }

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(() => {
        this.status = "We'll be in touch soon.";
        console.log(this.status);
        this.forceUpdate();
    })
      .catch((err) => {this.status = err.toString();
      this.forceUpdate();});

  };
    

    render() {
    if (this.status !== "not submitted") {
        return (
          <>
            <div className="h5 text-center">Thank you!</div>
            <div className="text-center">{this.status}</div>
          </>
        );
      }

        return (
            <div className="col-sm-10">
            <div className="h3 text-center">Get in touch!</div>
            <div>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="form-floating mb-3">
                        <input className="form-control" type="text" placeholder="name" id="name" required />
                        <label htmlFor="name">Name:</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input className="form-control" type="email" placeholder="Email" name="email" id="email" required />
                        <label htmlFor="email">Email:</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input className="form-control" type="tel" name="number" id="number" />
                        <label htmlFor="number">Phone number (if you want me to give you a ring!)</label>
                        {/* <p>Leave a number if you want me to give you a ring.</p> */}
                    </div>
                    <div className="mb-3">
                        {/* <label htmlFor="enquiry">What can I do for you?</label> */}
                        <textarea className="form-control" id = "enquiry" name="message" placeholder="What can I do for you?" required />
                    </div>
                        <button type="submit" className="btn btn-outline-dark">Submit</button>

                </form>
            </div>
            </div>
        )
    }
}

export default ContactForm;